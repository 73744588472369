import { once } from "lodash/fp";

import { IBootstrapResult } from "./types/IBootstrapResult";
import bootstrapAxios from "./axios";
import bootstrapStore from "./store";

/**
 * Initialization of app should be put here
 * All Repositories and othere things that are important to be ready before UI load
 * should be put here
 */
export default async function bootstrap(): Promise<IBootstrapResult> {
  // Put sync code below
  bootstrapAxios();
  const store = bootstrapStore();

  // Put async code below

  return { store };
}

/**
 * Complete invocation can be done only once
 * other invocations will return first taken result
 */
export const bootstrapOnce = once(bootstrap);
