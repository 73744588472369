import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { List, ListItem, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { getOrganisations } from "application/organisations";
import { IOrganisations } from 'domain/organisations/IOrganisations';
import { IDistrictItem } from "domain/workfields/IDistrictItem";
import { useDispatch } from "react-redux";
import { IAppState } from "state/types/IAppState";
import { viewMode } from "state/view-modes/selectors";
import { IViewModeState } from "state/view-modes/types/IViewModeState";
import { allDistrictsModeAction, districtOrganisationsModeAction, organisationModeAction } from "state/view-modes"

export default function OrganisationsComponent(districtItem: IDistrictItem) {

    const dispatch = useDispatch();
    const viewModeState = useSelector<IAppState, IViewModeState>(viewMode);
    const isExpanded = viewModeState.district !== undefined && (viewModeState.district === districtItem.name);
    const [organisations, updateOrganisations] = useState<IOrganisations>();
    useEffect(() => {
        let cleanupFunction = false;
        if (isExpanded) {            
            getOrganisations(districtItem.name, {
                onSuccess: (data) => {
                    if (!cleanupFunction)
                        updateOrganisations(data);
                },
                onError: (error) => { console.log(error); }
            });
        }
        return () => { cleanupFunction = true };
    }, [districtItem, isExpanded]);


    return (
        <div >
            <Accordion expanded={isExpanded} onChange={(event: object, expanded: boolean) => {
                if (!expanded) {
                    dispatch(allDistrictsModeAction());
                }
                else {
                    getOrganisations(districtItem.name, {
                        onSuccess: (data) => { updateOrganisations(data); dispatch(districtOrganisationsModeAction(districtItem.name)); },
                        onError: (error) => { console.log(error); }
                    });
                }
            }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h3>{districtItem.name}</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {
                            organisations?.items?.map((item, index) => {
                                return (
                                    <ListItem button key={index} onClick={() => dispatch(organisationModeAction(districtItem.name, item.name, null, null))}>
                                        <p>{item.name}</p>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>

    );
}