import { createAsyncAction, ActionType } from "typesafe-actions";

import { IError } from "domain/errors/IError";
import { IDistricts } from "domain/workfields/IDistricts";
import { IWorkfieldsState } from "./types/IWorkfieldsState";
import { WORKFIELDS } from "../actionTypes";
import { Severity } from "../alert/types/Severity";
import { queueAlert, AlertAction } from "../alert";

import { StandardThunk } from "../types/StandardThunk";

export const workfieldsRequestAction = createAsyncAction(
  WORKFIELDS.DISTRICTS.REQUEST,
  WORKFIELDS.DISTRICTS.SUCCESS,
  WORKFIELDS.DISTRICTS.ERROR
)<undefined, IDistricts, IError[]>();

export const loadDistricts = (): StandardThunk<WorkfieldsAction | AlertAction> => (
  dispatch,
  _getState,
  { workfieldsApi }
): void => {
  dispatch(workfieldsRequestAction.request());

  workfieldsApi.getWorkfieldDistricts({
    onSuccess: (payload) => {
      dispatch(workfieldsRequestAction.success(payload));
    },
    onError: (errors: IError[]) => {
      dispatch(workfieldsRequestAction.failure(errors));      
      const [firstError] = errors;
      dispatch(queueAlert(firstError.message, Severity.Error));
    },
  });
};

export type WorkfieldsAction = ActionType<typeof workfieldsRequestAction>;

export const workfieldsReducer = (
  state: IWorkfieldsState = {    
    loading: false,
    districts: null    
  },
  action: WorkfieldsAction
): IWorkfieldsState => {
  switch (action.type) {
    case WORKFIELDS.DISTRICTS.REQUEST:
      return {
        ...state,
        districts: null,        
        loading: true,
      };

    case WORKFIELDS.DISTRICTS.SUCCESS:
      return {
        ...state,
        districts: action.payload,
        loading: false,
      };

    case WORKFIELDS.DISTRICTS.ERROR:
      return { ...state, loading: false };    

    default:
      return state;
  }
};

