import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import { loadDistricts } from "state/workfields";
import { useDispatch, useSelector } from "react-redux";
import { getDistricts } from "state/workfields/selectors";
import { IDistricts } from "domain/workfields/IDistricts";
import OrganisationsComponent from 'components/organisations-list-component/organisations';
import { IAppState } from "state/types/IAppState";

const useStyles = makeStyles(theme => ({
    listItem: {
        display: "block"
    }
}));

export default function DistrictsListComponent() {
    const classes = useStyles();
    const report = useSelector<IAppState, IDistricts | null>(getDistricts);
    const districtsThunk = loadDistricts();
    const dispatch = useDispatch();
    const dispatchThunk = () => dispatch(districtsThunk);
    useEffect(() => { 
        dispatchThunk() 
    }, []);

    return (
        <>
            <List>
                {
                    report?.items.map((item, index) => {
                        return (
                            <ListItem className={classes.listItem} button key={index}>
                                <OrganisationsComponent name={item.name} itemsCount={item.itemsCount} />
                            </ListItem>
                        )
                    })
                }
            </List>
        </>
    );
}