import axios from "axios";

import { IError } from "domain/errors/IError";
import { IBaseMapItem } from "domain/base-map/IBaseMapItem";

import { IMethodCallbacks } from "./types/IMethodCallbacks";

export const getBaseMap = (
    callbacks: IMethodCallbacks<Array<IBaseMapItem>, IError[]>
): void => {
    axios
        .get<Array<IBaseMapItem>>("/basemap")
        .then(({ data }) => {
            callbacks.onSuccess(data);
        })
        .catch((error) => {
            callbacks.onError(error);
        });
};