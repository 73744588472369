import { applyMiddleware, createStore, Store } from "redux";
import thunkMiddleware from "redux-thunk";

import api from "../application";

import rootReducer from "./rootReducer";
import configureStorePersistance from "./persistence";
import { IAppState } from "./types/IAppState";

export default function configureStore(): Store<IAppState> {
  const middlewares = [thunkMiddleware.withExtraArgument(api)];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const store = configureStorePersistance(
    sessionStorage,
    (storedState: IAppState) =>
      createStore(rootReducer, storedState, middlewareEnhancer)
);

  return store;
}
