import { createAction, ActionType } from 'typesafe-actions';
import { VIEW_MODE } from "../actionTypes";
import { IViewModeState } from './types/IViewModeState';

export const allDistrictsModeAction = createAction(VIEW_MODE.ALL_DISTRICTS)();
export const districtOrganisationsModeAction = createAction(VIEW_MODE.DISTRICT_ORGANISATIONS, district => district)();
export const organisationModeAction = createAction(VIEW_MODE.ORGANISATION, (district, organisation, opisanie_c, objectid) => { return { district, organisation, opisanie_c, objectid }; })();
export const areaModeAction = createAction(VIEW_MODE.AREA, (district, organisation, opisanie_c, objectid) => { return { district, organisation, opisanie_c, objectid }; })();

export type ViewModeAction = ActionType<typeof allDistrictsModeAction>
    | ActionType<typeof districtOrganisationsModeAction>
    | ActionType<typeof organisationModeAction>
    | ActionType<typeof areaModeAction>;

export const viewModeReducer = (
    state: IViewModeState = {
        mode: VIEW_MODE.ALL_DISTRICTS,
        district: null,
        organisation: null,
        opisanie_c: null,
        objectid: null,
    },
    action: ViewModeAction
): IViewModeState => {
    switch (action.type) {
        case VIEW_MODE.ALL_DISTRICTS:
            return {
                ...state,
                mode: VIEW_MODE.ALL_DISTRICTS,
                district: null,
                organisation: null,
                opisanie_c: null,
                objectid: null
            };
        case VIEW_MODE.DISTRICT_ORGANISATIONS:
            return {
                ...state,
                mode: VIEW_MODE.DISTRICT_ORGANISATIONS,
                district: action.payload,
                organisation: null,
                opisanie_c: null,
                objectid: null
            };
        case VIEW_MODE.ORGANISATION:
            return {
                ...state,
                mode: VIEW_MODE.ORGANISATION,
                district: action.payload.district,
                organisation: action.payload.organisation,
                opisanie_c: action.payload.opisanie_c,
                objectid: action.payload.objectid
            };
        case VIEW_MODE.AREA:
            return {
                ...state,
                mode: VIEW_MODE.AREA,
                district: action.payload.district,
                organisation: action.payload.organisation,
                opisanie_c: action.payload.opisanie_c,
                objectid: action.payload.objectid
            };
        default:
            return state;
    }
};


