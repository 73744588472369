import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "state/types/IAppState";
import { viewMode } from "state/view-modes/selectors";
import { IViewModeState } from "state/view-modes/types/IViewModeState";
import Link from '@material-ui/core/Link';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';
import { districtOrganisationsModeAction, allDistrictsModeAction, organisationModeAction, areaModeAction } from "state/view-modes";
import Pagination from '@material-ui/lab/Pagination';
import { VIEW_MODE } from "state/actionTypes";
import { IAreas } from 'domain/areas/IAreas';
import { getAreas } from "application/areas";

const useStyles = makeStyles(theme => ({
    arrow: {
        fontSize: 24,
    },
    container: {
        display: "flex",
        alignItems: "center"
    }
}));

const StyledPagination = withStyles({
    ul: {
        flexWrap: "nowrap"
    }
})(Pagination);

export default function GroupNavigationComponent() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const viewModeState = useSelector<IAppState, IViewModeState>(viewMode);
    const [areas, updateAreas] = useState<IAreas>();
    useEffect(() => {
        if (viewModeState.district && viewModeState.organisation)
            getAreas(viewModeState.district, viewModeState.organisation, {
                onSuccess: (data) => { updateAreas(data); },
                onError: (error) => { console.log(error); }
            });
    }, [viewModeState]);
    const currentAreasGroup = areas?.items.filter(x => x.Opisanie_c === viewModeState.opisanie_c)[0];
    const page = (currentAreasGroup?.areas?.findIndex(x => x.OBJECTID === viewModeState.objectid) ?? 0) + 1;

    return (
        <>
            <Toolbar>
                <div>
                    <Typography variant="subtitle2" className={classes.container} gutterBottom>
                        <Link component="button" variant="subtitle2" onClick={() => dispatch(allDistrictsModeAction())}>
                            Все районы
                        </Link>
                        {viewModeState.district && <ArrowRightIcon className={classes.arrow} />}
                        {viewModeState.district && <Link component="button" variant="subtitle2" onClick={() => dispatch(districtOrganisationsModeAction(viewModeState.district))}>
                            {viewModeState.district}
                        </Link>}
                        {viewModeState.organisation && <ArrowRightIcon className={classes.arrow} />}
                        {viewModeState.organisation && <Link component="button" variant="subtitle2" onClick={() => dispatch(organisationModeAction(viewModeState.district, viewModeState.organisation, viewModeState.opisanie_c, viewModeState.objectid))}>
                            {viewModeState.organisation}
                        </Link>}
                    </Typography>
                    {viewModeState.opisanie_c && viewModeState.organisation && viewModeState.mode === VIEW_MODE.AREA &&
                        <Typography variant="subtitle2" className={classes.container} gutterBottom>
                            {viewModeState.opisanie_c} :
                            <StyledPagination count={currentAreasGroup?.areas.length} page={page} siblingCount={1} defaultChecked color="primary"
                                onChange={(_event: ChangeEvent<unknown>, page: number) => {
                                    dispatch(areaModeAction(viewModeState.district, viewModeState.organisation, currentAreasGroup?.Opisanie_c, currentAreasGroup?.areas[page - 1].OBJECTID))
                                }} />
                        </Typography>}
                </div>
            </Toolbar>
        </>
    );
}