import { createAction, ActionType } from 'typesafe-actions';
import { SIDE_MENU } from "../actionTypes";
import { ISideMenuState } from './types/ISideMenuState';

export const sideMenuOpenAction = createAction(SIDE_MENU.OPEN)();
export const sideMenuCloseAction = createAction(SIDE_MENU.CLOSE)();

export type SideMenuAction = ActionType<typeof sideMenuOpenAction> | ActionType<typeof sideMenuCloseAction>;

export const sideMenuReducer = (
    state: ISideMenuState = {
        opened: false
    },
    action: SideMenuAction
): ISideMenuState => {
    switch (action.type) {
        case SIDE_MENU.OPEN:            
            return {
                ...state,
                opened: true
            };
        case SIDE_MENU.CLOSE:            
            return {
                ...state,
                opened: false
            };
        default:
            return state;
    }
};