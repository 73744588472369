import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "state/types/IAppState";
import { viewMode } from "state/view-modes/selectors";
import { IViewModeState } from "state/view-modes/types/IViewModeState";
import L from "leaflet";
import { IFeatures } from 'domain/features/IFeatures';
import { getBaseMap } from 'application/base-map';
import { getMapNavigation } from 'application/navigation';
import { getFeatures } from 'application/features';
import { IMapNavigation } from 'domain/navigation/IMapNavigation';
import VectorStyles from './colors';
import VegetClassStyles from './veget-class-colors';
import { VIEW_MODE } from "state/actionTypes";
import * as geojson from 'geojson';
import { areaModeAction, districtOrganisationsModeAction } from "state/view-modes"

interface ILayersDictionary {
    [key: string]: L.GeoJSON<any>;
}

let map: L.Map;
let selectionLayer: L.GeoJSON<any>;
let tileLayer: L.TileLayer;
let districtLayers: ILayersDictionary = {};
let organisationLayer: L.GeoJSON<any>;
let baseMapLayer: L.GeoJSON<any>;
let currentOrganisation: string | null;

export default function MapComponent() {
    const dispatch = useDispatch();
    const viewModeState = useSelector<IAppState, IViewModeState>(viewMode);
    const obalLat = 55.357343;
    const obalLng = 29.281937;
    const zoom = 8;

    useEffect(() => {
        map = L.map("map", {
            center: [obalLat, obalLng],
            minZoom: 0,
        });


        //hybrid
        tileLayer = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', { maxZoom: 20, subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] }).addTo(map);

        map.setView([obalLat, obalLng], zoom);

    }, []);

    useEffect(() => {
        switch (viewModeState.mode) {
            case VIEW_MODE.ALL_DISTRICTS:
                map.eachLayer((l) => { if (l !== tileLayer) map.removeLayer(l) });
                if (!baseMapLayer) {
                    baseMapLayer = L.geoJSON().addTo(map);
                    baseMapLayer.on('click', (e) => {
                        dispatch(districtOrganisationsModeAction(e.propagatedFrom.feature.geometry.district));
                    });
                    getBaseMap({
                        onSuccess: (f) => {
                            f.forEach(i => {
                                baseMapLayer.addData(i);
                            });
                        },
                        onError: (error) => { console.log(error); }
                    });
                }
                else {
                    if (!map.hasLayer(baseMapLayer)) {
                        map.addLayer(baseMapLayer);
                    }
                }
                navigateToMap();
                break;
            case VIEW_MODE.DISTRICT_ORGANISATIONS:
                let currentDistrictName = viewModeState.district;
                if (currentDistrictName) {
                    map.eachLayer((l) => { if (l === selectionLayer || l === organisationLayer || l === baseMapLayer) map.removeLayer(l) });
                    if (!districtLayers[currentDistrictName]) {
                        districtLayers[currentDistrictName] = L.geoJSON(undefined, {
                            style: ((e) => {
                                return VegetClassStyles[e?.properties.veget_clas];
                            }),
                        }).addTo(map);
                        let l = districtLayers[currentDistrictName];
                        l.on('click', (e) => {
                            dispatch(areaModeAction(e.propagatedFrom.feature.properties.Rajon, e.propagatedFrom.feature.properties.UsName, e.propagatedFrom.feature.properties.Opisanie_c, e.propagatedFrom.feature.properties.OBJECTID));
                        });
                        getFeatures(currentDistrictName, null, null, {
                            onSuccess: (f) => setFeature(0, f.items.length, l, f.items),
                            onError: (error) => { console.log(error); }
                        });
                    }
                    else {
                        if (!map.hasLayer(districtLayers[currentDistrictName])) {
                            map.addLayer(districtLayers[currentDistrictName]);
                        }
                    }
                    navigateToMap();
                }

                break;


            case VIEW_MODE.ORGANISATION:
            case VIEW_MODE.AREA:
                map.eachLayer((l) => { if (l !== tileLayer && !(l === organisationLayer && currentOrganisation !== viewModeState.organisation)) map.removeLayer(l) });
                if (!organisationLayer || currentOrganisation !== viewModeState.organisation) {
                    getFeatures(viewModeState.district, viewModeState.organisation, null, {
                        onSuccess: (data) => {
                            organisationLayer = L.geoJSON(undefined, {
                                style: (e) => {
                                    return VegetClassStyles[e?.properties.veget_clas];
                                },
                            });
                            organisationLayer.on('click', (e) => {
                                dispatch(areaModeAction(e.propagatedFrom.feature.properties.Rajon, e.propagatedFrom.feature.properties.UsName, e.propagatedFrom.feature.properties.Opisanie_c, e.propagatedFrom.feature.properties.OBJECTID));
                            });
                            setFeature(0, data.items.length, organisationLayer, data.items);
                            map.addLayer(organisationLayer);
                        },
                        onError: (error) => { console.log(error); }
                    });
                }
                else {
                    if (!map.hasLayer(organisationLayer)) {
                        map.addLayer(organisationLayer);
                    }
                }
                if (viewModeState.objectid !== null) {
                    getFeatures(viewModeState.district, viewModeState.organisation, viewModeState.objectid, {
                        onSuccess: setSelectionMap,
                        onError: (error) => { console.log(error); }
                    });
                }
                navigateToMap();
                break;
        }
        currentOrganisation = viewModeState.organisation;
    }, [viewModeState]);

    const setViewMap = (data: IMapNavigation) => {
        if (map) {
            map.setView([data.lat, data.lng], data.zoom);
        }
    };
    const setSelectionMap = (data: IFeatures) => {
        if (map) {
            map.eachLayer((l) => { if (l === selectionLayer) map.removeLayer(l) });
            selectionLayer = L.geoJSON(data.items[0], {
                style: VectorStyles["selection"],
            });
            selectionLayer.setZIndex(99999);
            selectionLayer.addTo(map);
        }
    };

    const setFeature = (index: number, maxIndex: number, layer: L.GeoJSON<any>, items: Array<geojson.Feature<geojson.MultiPolygon>>) => {
        layer.addData(items[index]);
        let next = index + 1;
        if (next < items.length && next < maxIndex) {
            setFeature(next, maxIndex, layer, items);
            // setTimeout(() => setFeature(next, maxIndex, layer, items), 0);
        }
    }

    const navigateToMap = () => {
        getMapNavigation(viewModeState.district, viewModeState.organisation, viewModeState.objectid, {
            onSuccess: setViewMap,
            onError: (error) => { console.log(error); }
        });
    };


    return (
        <>
            <div id="map" className="map"></div>
        </>
    );
}