import React from 'react';
import { Toolbar, IconButton, Typography } from '@material-ui/core';
import { ExitToApp, Close } from '@material-ui/icons';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { sideMenuCloseAction } from "state/side-menu"
import { useDispatch } from "react-redux";
import GroupNavigationComponent from 'components/group-navigation-component/group-navigation'

export default function MainMenuComponent() {
    const dispatch = useDispatch();    

    return (
        <div>
            <Toolbar className="side-toolbar">
                <IconButton onClick={() => dispatch(sideMenuCloseAction())} edge="start" color="inherit" aria-label="close">
                    <Close style={{ fontSize: 30 }} />
                </IconButton>
                <div className="logo-text">ПикВНЗ</div>
                <div className="logo-placeholder" />
                <IconButton onClick={() => (document.getElementById("amplifySignOut")?.shadowRoot?.firstElementChild?.shadowRoot?.lastElementChild as HTMLElement)?.click()} edge="start" color="inherit" aria-label="close">
                    <Typography variant="subtitle2">
                        Выход
                    </Typography>
                    <ExitToApp style={{ fontSize: 30 }} />
                </IconButton>
                <AmplifySignOut id="amplifySignOut" hidden />
            </Toolbar>
            <GroupNavigationComponent />
        </div>
    );
}