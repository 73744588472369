import axios from "axios";

import { IError } from "domain/errors/IError";
import { IDistricts } from "domain/workfields/IDistricts";

import { IMethodCallbacks } from "./types/IMethodCallbacks";

export const getWorkfieldDistricts = (  
  callbacks: IMethodCallbacks<IDistricts, IError[]>
): void => {
  axios
    .get<IDistricts>("/districts")
    .then(({ data }) => {
      callbacks.onSuccess(data);
    })
    .catch((error) => {
      callbacks.onError(error);
    });
};