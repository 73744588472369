interface IStylesDictionary {
    [key: string]: any;
}

const genericStyles: IStylesDictionary =
{
    "selection": {
        "color": "#e30e15",
        "weight": 5,
    },
    "Полоцкий район": {
        "color": "#15e645",
        "weight": 2,
        "opacity": 0.65
    },
    "Витебский район": {
        "color": "#2300a3",
        "weight": 2,
        "opacity": 0.65
    },
    "default": {
        "color": "#e3a8a8",
        "weight": 2,
        "opacity": 0.65
    }
};

export default genericStyles;