import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import { sideMenuOpenAction } from "state/side-menu"
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "state/types/IAppState";
import { isSideMenuOpened } from "state/side-menu/selectors";

const useStyles = makeStyles(theme => ({
    button: {
        borderTopLeftRadius: "100%",
        borderBottomLeftRadius: "100%",
        position: "fixed",
        height: "200px",
        top: "50%",
        left: "100%",
        zIndex: 9999999,
        transform: "translate(-100%, -50%)"
    }
}));

export default function SideButtonComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isOpened = useSelector<IAppState, boolean>(isSideMenuOpened);

    return (
        <>
            {!isOpened && <Button className={classes.button} onClick={() => dispatch(sideMenuOpenAction())} variant="contained" color="primary" disableElevation>
                <Menu style={{ fontSize: 40 }} />
            </Button>}
        </>
    );
}