import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Divider, ListItemAvatar, Avatar } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { makeStyles } from '@material-ui/core/styles';
import { IAreaInfoDetails } from "domain/area-details/IAreaInfoDetails";
import { useSelector } from "react-redux";
import { IAppState } from "state/types/IAppState";
import { IViewModeState } from "state/view-modes/types/IViewModeState";
import { viewMode } from "state/view-modes/selectors";
import { getAreaDetailsById } from "application/area-details";

const useStyles = makeStyles(theme => ({
    listItem: {
        display: "flex",
        marginLeft: theme.spacing(1)
    }
}));

export default function AreaDetailsComponent() {
    const classes = useStyles();
    const viewModeState = useSelector<IAppState, IViewModeState>(viewMode);
    const [details, updateDetails] = useState<IAreaInfoDetails>();
    useEffect(() => {
        if (viewModeState.objectid)
            getAreaDetailsById(viewModeState.objectid, {
                onSuccess: (data) => { updateDetails(data); },
                onError: (error) => { console.log(error); }
            });
    }, [viewModeState]);
    return (
        <>
            <List>
                <ListItem className={classes.listItem} key={details?.class}>
                    <ListItemAvatar>
                        <Avatar>
                            <RadioButtonCheckedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Группа земель по фактическому состоянию растительности:  " secondary={details?.class} />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem className={classes.listItem} key={details?.area}>
                    <ListItemAvatar>
                        <Avatar>
                            <RadioButtonCheckedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Площадь земельного участка, га:" secondary={details?.area} />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem className={classes.listItem} key={details?.landCode}>
                    <ListItemAvatar>
                        <Avatar>
                            <RadioButtonCheckedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Код вида земель:" secondary={details?.landCode} />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem className={classes.listItem} key={details?.summary}>
                    <ListItemAvatar>
                        <Avatar>
                            <RadioButtonCheckedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Оценка агроэкологического состояния земельного участка:" secondary={details?.summary} />
                </ListItem>
                <Divider variant="inset" component="li" />
                {details && details.factors.length > 0 && <ListItem className={classes.listItem} key={details?.factors.toString()}>
                    <ListItemAvatar>
                        <Avatar>
                            <RadioButtonCheckedIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Лимитирующие факторы:" secondary={details?.factors.map((item) => {
                        return (
                            <span key={item}>- {item} <br/></span>
                        );
                    })} />
                </ListItem>}
                {details && details.factors.length > 0 && <Divider variant="inset" component="li" />}
            </List>
        </>
    );
}