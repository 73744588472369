import axios from "axios";

import { IError } from "domain/errors/IError";
import { IOrganisations } from "domain/organisations/IOrganisations";

import { IMethodCallbacks } from "./types/IMethodCallbacks";

export const getOrganisations = (
  district : string,  
  callbacks: IMethodCallbacks<IOrganisations, IError[]>
): void => {
  axios
    .get<IOrganisations>("/organisations?district=" + district)
    .then(({ data }) => {
      callbacks.onSuccess(data);
    })
    .catch((error) => {
      callbacks.onError(error);
    });
};