import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function MainLoaderComponent()
{
    return (
        <div className="centered">
          <div className="main-loader">
            <CircularProgress/>
          </div>
          <h2 className="main-loader-text">Loading...</h2>
        </div>
      );
}