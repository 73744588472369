import { combineReducers } from "redux";

import { IAppState } from "./types/IAppState";
import { alertReducer } from "./alert";
import { workfieldsReducer } from "./workfields";
import { sideMenuReducer } from "./side-menu";
import { viewModeReducer } from "./view-modes";

export default combineReducers<IAppState>({
  alert: alertReducer,
  workfields: workfieldsReducer,
  sideMenu: sideMenuReducer,
  viewMode: viewModeReducer
});
