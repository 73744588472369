interface IStylesDictionary {
    [key: string]: any;
}

const vegetClassStyles: IStylesDictionary =
{
    "Пахотные земли под агрофитоценозами": {
        "color": "#6b4d27",
        "weight": 3
    },
    "Бурьянистые залежи": {
        "color": "#f5f53d",
        "weight": 3
    },
    "Корневищные и рыхлокустовые залежи": {
        "color": "#eb7d2f",
        "weight": 3
    },
    "Рудерализированные и закустаренные луга": {
        "color": "#c2f5a4",
        "weight": 3
    },
    "Древесно-кустарниковая растительность": {
        "color": "#5fde14",
        "weight": 3
    }
};

export default vegetClassStyles;
