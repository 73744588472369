import { createAction, ActionType } from "typesafe-actions";
import uniqueId from "lodash/fp/uniqueId";
import map from "lodash/fp/map";
import reject from "lodash/fp/reject";

import { IAlertState } from "./types/IAlertState";
import { IPostition } from "./types/IPosition";
import { Severity } from "./types/Severity";
import { ALERT } from "../actionTypes";

export const queueAlert = createAction(
  ALERT.QUEUE,
  (
    message: string,
    severity: Severity = Severity.Default,
    position: IPostition = {
      vertical: "bottom",
      horizontal: "center",
    }
  ) => ({
    key: uniqueId("alert"),
    open: true,
    severity,
    message,
    position,
  })
)();

export const dequeueAlert = createAction(ALERT.DEQUEUE)<string>();
export const closeAlert = createAction(ALERT.CLOSE)<string>();

export type AlertAction =
  | ActionType<typeof queueAlert>
  | ActionType<typeof dequeueAlert>
  | ActionType<typeof closeAlert>;

export const alertReducer = (
  state: IAlertState = { queue: [] },
  action: AlertAction
): IAlertState => {
  switch (action.type) {
    case ALERT.QUEUE:
      return {
        ...state,
        queue: [...state.queue, { ...action.payload }],
      };

    case ALERT.CLOSE:
      return {
        ...state,
        queue: map(
          (alert) =>
            alert.key === action.payload ? { ...alert, open: false } : alert,
          state.queue
        ),
      };

    case ALERT.DEQUEUE:
      return {
        ...state,
        queue: reject({ key: action.payload }, state.queue),
      };

    default:
      return state;
  }
};
