import { Store } from "redux";

import { IAppState } from "./types/IAppState";

const STORE_NAME = "__PICKVNZ_@_LOCAL__";

const getLocalState = (storage: Storage): IAppState | undefined => {
  let state: IAppState | undefined;

  try {
    const storedState = storage.getItem(STORE_NAME);

    if (storedState) {
      state = JSON.parse(storedState);
    }
  } catch (_err) {
    state = undefined;
  }

  return state;
};

const setLocalState = (storage: Storage, state: IAppState): void => {
  storage.setItem(STORE_NAME, JSON.stringify(state));
};

export default function configureStorePersistance(
  storage: Storage,
  createStoreWrapper: (store: IAppState) => Store<IAppState>
): Store<IAppState> {
  const storedState = getLocalState(storage) as IAppState;
  const store = createStoreWrapper(storedState);

  store.subscribe(() => {
    const state = store.getState();

    setLocalState(storage, {
      ...state,
    });
  });

  return store;
}
