import axios from "axios";

import { IError } from "domain/errors/IError";
import { IMapNavigation } from "domain/navigation/IMapNavigation";

import { IMethodCallbacks } from "./types/IMethodCallbacks";

export const getMapNavigation = (
    district: string | null,
    organisation: string | null,
    id: number | null,
    callbacks: IMethodCallbacks<IMapNavigation, IError[]>
): void => {
    axios
        .get<IMapNavigation>("/navigation", {
            params: {
                district: district,
                organisation: organisation,
                id: id
            }
        })
        .then(({ data }) => {
            callbacks.onSuccess(data);
        })
        .catch((error) => {
            callbacks.onError(error);
        });
};