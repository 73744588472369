import React from 'react';
import './main.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import MapComponent from './components/map-component/map';
import DrawerComponent from './components/drawer-component/drawer';
import SideButton from './components/side-button-component/side-button';
import { Container, CssBaseline } from "@material-ui/core";
import { bootstrapOnce } from "./bootstrap";
import { useAsyncEffect } from "./shared/hooks";
import { Provider } from 'react-redux';
import MainLoaderComponent from './components/main-loader-component/main-loader';

Amplify.configure(awsconfig);

function App() {
  let bootstrapResult = useAsyncEffect({ asyncAction: bootstrapOnce }, []);
  //add loader here
  if (bootstrapResult.data != null && !bootstrapResult.loading) {
    return (
      <>
        <Provider store={bootstrapResult.data.store}>
          <Container disableGutters maxWidth={false} className="container">
            <CssBaseline />
            <SideButton/>
            <MapComponent />
            <DrawerComponent />            
          </Container>
        </Provider>
      </>
    );
  }
  return (
    <MainLoaderComponent />
  );
}

export default withAuthenticator(App);