export const WORKFIELDS = {
  DISTRICTS: {
    REQUEST: "/WORKFIELDS/DISTRICTS/REQUEST",
    SUCCESS: "/WORKFIELDS/DISTRICTS/SUCCESS",
    ERROR: "/WORKFIELDS/DISTRICTS/ERROR",
  },
} as const;

export const ALERT = {
  CLOSE: "/ALERT/CLOSE",
  QUEUE: "/ALERT/QUEUE",
  DEQUEUE: "/ALERT/DEQUEUE",
} as const;

export const SIDE_MENU = {
  OPEN: "/SIDE_MENU/OPEN",
  CLOSE: "/SIDE_MENU/CLOSE",
} as const;

export const ORGANISATIONS = {
  REQUEST: "/ORGANISATIONS/REQUEST",
  SUCCESS: "/ORGANISATIONS/SUCCESS",
  ERROR: "/ORGANISATIONS/ERROR",
} as const;

export enum VIEW_MODE {
  ALL_DISTRICTS = "/MODE/ALL_DISTRICTS",
  DISTRICT_ORGANISATIONS = "/MODE/DISTRICT_ORGANISATIONS",
  ORGANISATION = "/MODE/ORGANISATION",  
  AREA = "/MODE/AREA"
};