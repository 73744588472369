import axios from "axios";

import { IError } from "domain/errors/IError";
import { IAreaInfoDetails } from "domain/area-details/IAreaInfoDetails";

import { IMethodCallbacks } from "./types/IMethodCallbacks";

export const getAreaDetailsById = (
    id: number,
    callbacks: IMethodCallbacks<IAreaInfoDetails, IError[]>
): void => {
    axios
        .get<IAreaInfoDetails>(`/details?id=${id}`)
        .then(({ data }) => {
            callbacks.onSuccess(data);
        })
        .catch((error) => {
            callbacks.onError(error);
        });
};