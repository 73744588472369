import axios from "axios";
// import { setupCache } from 'axios-cache-adapter'

import { IError } from "domain/errors/IError";
import { IAreas } from "domain/areas/IAreas";

import { IMethodCallbacks } from "./types/IMethodCallbacks";

// const cache = setupCache({
//     maxAge: 15 * 60 * 1000
//   })

//   const api = axios.create({
//     adapter: cache.adapter
//   })

export const getAreas = (
    district: string,
    organisation: string,
    callbacks: IMethodCallbacks<IAreas, IError[]>
): void => {
    axios
        .get<IAreas>(`/areas?district=${district}&organisation=${organisation}`)
        .then(({ data }) => {
            callbacks.onSuccess(data);
        })
        .catch((error) => {
            callbacks.onError(error);
        });
};