import React from 'react';
import { Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { isDistrictsLoading } from "state/workfields/selectors";
import { isSideMenuOpened } from "state/side-menu/selectors";
import { IAppState } from "state/types/IAppState";
import { viewMode } from "state/view-modes/selectors";
import { IViewModeState } from "state/view-modes/types/IViewModeState";
import MainLoaderComponent from 'components/main-loader-component/main-loader';
import MainMenuComponent from "../main-menu-component/main-menu";
import DistrictsListComponent from 'components/districts-list-component/districts-list';
import AreasNavigationComponent from 'components/areas-navigation-component/areas-navigation'
import { VIEW_MODE } from "state/actionTypes"
import AreaDetailsComponent from 'components/area-details-component/area-details';

const useStyles = makeStyles(theme => ({
    list: {
        maxHeight: "100%",
        overflow: "auto",
        position: "fixed",
        top: "0%",
        left: "100%",
        transform: "translate(-100%, 0%)",
        zIndex: 99999,
        height: "100%",
        backgroundColor: "#fff"
    },
    collapsed: {
        width: 0
    },
    visible: {
        width: "auto"
    }
}));

export default function DrawerComponent() {
    const classes = useStyles();
    const reportLoading = useSelector<IAppState, boolean>(isDistrictsLoading);
    const isOpened = useSelector<IAppState, boolean>(isSideMenuOpened);
    const viewModeState = useSelector<IAppState, IViewModeState>(viewMode);

    return (
        <>
            {<div className={`${classes.list} ${isOpened ? classes.visible : classes.collapsed}`}>
                <Slide direction="left" in={isOpened}>
                    <div>
                        <MainMenuComponent />
                        {reportLoading && <MainLoaderComponent />}
                        {(viewModeState.mode === VIEW_MODE.ALL_DISTRICTS || viewModeState.mode === VIEW_MODE.DISTRICT_ORGANISATIONS) && <DistrictsListComponent />}
                        {viewModeState.mode === VIEW_MODE.ORGANISATION && <AreasNavigationComponent />}   
                        {viewModeState.mode === VIEW_MODE.AREA && <AreaDetailsComponent />}                     
                    </div>
                </Slide>
            </div>}
        </>
    );
}