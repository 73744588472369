/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, DependencyList } from "react";
import { IAsyncEffectProps } from "./types/IAsyncEffectProps";
import { IAsyncEffectResult } from "./types/IAsyncEffectResult";

export const useAsyncEffect = <R, E extends Error>(
  props: IAsyncEffectProps<R>,
  deps: DependencyList
): IAsyncEffectResult<R, E> => {
  const { disposeAction, asyncAction } = props;
  const invalidatedRun = useRef(false);
  const [innerState, setData] = useState<IAsyncEffectResult<R, E>>({
    loading: true,
    data: null,
    error: null,
  });

  useEffect(() => {
    if (invalidatedRun.current) {
      setData({
        loading: true,
        data: innerState.data,
        error: null,
      });
    }

    invalidatedRun.current = true;

    const promise: Promise<R> = asyncAction();

    promise.then(
      (result) => {
        setData({
          loading: false,
          data: (result as unknown) as R,
          error: null,
        });
      },
      (error) => {
        setData({
          loading: false,
          data: innerState.data,
          error,
        });
      }
    );

    return disposeAction;
  }, deps);

  return innerState;
};
