import React, { useState, useEffect, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { getAreas } from "application/areas";
import { IAppState } from "state/types/IAppState";
import { IViewModeState } from "state/view-modes/types/IViewModeState";
import { IAreas } from 'domain/areas/IAreas';
import { ExpandMore } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { areaModeAction } from "state/view-modes"
import { viewMode } from "state/view-modes/selectors";

const useStyles = makeStyles(theme => ({
    listItem: {
        display: "block"
    }
}));

export default function AreasNavigationComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const viewModeState = useSelector<IAppState, IViewModeState>(viewMode);
    const [areas, updateAreas] = useState<IAreas>();
    useEffect(() => {
        if (viewModeState.district && viewModeState.organisation)
            getAreas(viewModeState.district, viewModeState.organisation, {
                onSuccess: (data) => { updateAreas(data); },
                onError: (error) => { console.log(error); }
            });
    }, [viewModeState]);
    const currentAreasGroup = areas?.items.filter(x => x.Opisanie_c === viewModeState.opisanie_c)[0];
    const page = (currentAreasGroup?.areas?.findIndex(x => x.OBJECTID === viewModeState.objectid) ?? 0) + 1;

    return (
        <>
            <List>
                {
                    areas?.items.map((item, index) => {
                        return (
                            <ListItem className={classes.listItem} button key={index}>
                                <Accordion defaultExpanded={currentAreasGroup?.Opisanie_c === item.Opisanie_c}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <h3>{item.Opisanie_c}</h3>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Pagination count={item.areas.length} defaultPage={currentAreasGroup?.Opisanie_c === item.Opisanie_c ? page : 0} siblingCount={item.areas.length} hidePrevButton hideNextButton color="primary"
                                            onChange={(_event: ChangeEvent<unknown>, page: number) => {
                                                dispatch(areaModeAction(viewModeState.district, viewModeState.organisation, item.Opisanie_c, item.areas[page - 1].OBJECTID))
                                            }} />
                                    </AccordionDetails>
                                </Accordion>
                            </ListItem>
                        )
                    })
                }
            </List>
        </>
    );
}